import React from 'react';
import "./error.css";


function Error () {
    return (
       <div>
           <h1 id="error">404!</h1>
       </div>
    )
}

export default Error;